<template>
  <v-layout
    align-center
    justify-center
  >
    <v-card
      :loading="loading"
      :elevation="$vuetify.breakpoint.xs ? 0 : 2"
      :outlined="$vuetify.breakpoint.xs ? false : true"
      class="mx-auto my-12"
      max-width="374"
      v-for="(item, index) in items"
      :key="index"
    >
      <v-img
        max-width="50%"
        class="mx-auto my-4"
        :src="require('../assets/iconos/alvarez-resumen-de-cuenta.svg')"
      ></v-img>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ item.NombreArchivo }}</v-list-item-title>
          <v-list-item-subtitle>{{ item.FechaResultado }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action class="align-self-center">
          <v-btn
            :href="`https://docs.google.com/viewer?url=${item.pdf}`"
            target="_blank"
            icon
          >
            <v-img
              :src="require('../assets/iconos/alvarez-compartir.svg')"
              aspect-ratio="1"
              contain
              width="40"
              height="40"
            ></v-img>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-card>
  </v-layout>
</template>

<script>

import moment from "moment";

export default {
  data: () => ({
    loading: false,
    items: [],
    total: 0,
    options: {},
    filtro: {
      desde: moment().subtract(1, 'months').format('YYYY-DD-MM'),
      hasta: moment().format('YYYY-DD-MM'),
      pagina_solicitada: 1,
      registrosPorPagina: 1,
      importe_total: 0,
      importe_total_iva: null,
      importe_total_neto: null,
    },
  }),

  methods: {
    query: function () {
      this.loading = true

      this.$soapClient.call('listarResumenes', {
        filtro: this.filtro,
      })
      .then((response) => {
        this.items = response.data.resultado
        this.total = response.data.filtros.totalRegistros
      })
      .catch((error) => {
        if ('message' in error) {
          this.$eventHub.$emit('snackbar-message', error.message, 'error')
        } else {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .then(() => {
        this.loading = false
      })
    },

    download (item) {
      let link = document.createElement("a");
      link.setAttribute('download', item.NombreArchivo);
      link.href = item.pdf;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      link.remove();
    },
  },

  created() {
    this.query()
  }
};
</script>
